import axios from '../../plugins/axios'

const getNoticeList = (options) => axios.post('/notification/list', options)

// const getOperatorBalance = () => axios.get('/capital/query_platform_balance')

const bindNotice = (options) => axios.post('/notification/create', options)
const checkNotice = (options) => axios.get('/notification/check/' + options.id)
const unBindNotice = (options) => axios.post('/notification/unbind', options)
export default {
  getNoticeList,
  bindNotice,
  checkNotice,
  unBindNotice
}
