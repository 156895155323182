// 年级
const grade = {
  1: '七年级',
  2: '八年级',
  3: '九年级',
  4: '高一',
  5: '高二',
  6: '高三',
  7: '一年级',
  8: '二年级',
  9: '三年级',
  10: '四年级',
  11: '五年级',
  12: '六年级'
}

// 年级与学段的映射（通过id映射）
const gradeToPhaseById = {
  // 七年级-九年级(年级id 1-3) => 初中(学段id 1)
  1: 1,
  2: 1,
  3: 1,

  // 高一-高三(年级id 4-6) => 高中(学段id 2)
  4: 2,
  5: 2,
  6: 2,

  // 一年级-七年级(年级id 7-12) => 小学(学段id 3)
  7: 3,
  8: 3,
  9: 3,
  10: 3,
  11: 3,
  12: 3
}

// 学段与年级映射（通过id映射）
const phaseToGradeById = {
  3: [7, 8, 9, 10, 11, 12], // 小学
  1: [1, 2, 3], // 初中
  2: [4, 5, 6]
}

// 学段与年级映射（通过下拉框配置映射）
const phaseToGradeBySelect = {
  3: [
    {
      label: '一年级',
      value: 7
    },
    {
      label: '二年级',
      value: 8
    },
    {
      label: '三年级',
      value: 9
    },
    {
      label: '四年级',
      value: 10
    },
    {
      label: '五年级',
      value: 11
    },
    {
      label: '六年级',
      value: 12
    }

  ],
  1: [
    {
      label: '七年级',
      value: 1
    },
    {
      label: '八年级',
      value: 2
    },
    {
      label: '九年级',
      value: 3
    }
  ],
  2: [
    {
      label: '高一',
      value: 4
    },
    {
      label: '高二',
      value: 5
    },
    {
      label: '高三',
      value: 6
    }
  ]
}

// 试卷类型
const paperTypeArray = {
  // 小学试卷类型（小学学段id：3）
  3: [
    {
      label: '月考',
      value: 1
    },
    {
      label: '单元测试',
      value: 4
    },
    {
      label: '期中考试',
      value: 5
    },
    {
      label: '期末考试',
      value: 6
    },
    {
      label: '大型联考',
      value: 9
    }
  ],
  // 初中试卷类型（初中学段id：1）
  1: [
    {
      label: '月考',
      value: 1
    },
    {
      label: '中考',
      value: 2
    },
    {
      label: '单元测试',
      value: 4
    },
    {
      label: '期中考试',
      value: 5
    },
    {
      label: '期末考试',
      value: 6
    },
    {
      label: '中考模拟',
      value: 7
    },
    {
      label: '大型联考',
      value: 9
    },
    {
      label: '基础培优',
      value: 10
    },
    {
      label: '进阶培优',
      value: 11
    }
  ],
  // 高中试卷类型（高中学段id：2）
  2: [
    {
      label: '月考',
      value: 1
    },
    {
      label: '高考',
      value: 3
    },
    {
      label: '单元测试',
      value: 4
    },
    {
      label: '期中考试',
      value: 5
    },
    {
      label: '期末考试',
      value: 6
    },
    {
      label: '高考模拟',
      value: 8
    },
    {
      label: '大型联考',
      value: 9
    },
    {
      label: '基础培优',
      value: 10
    },
    {
      label: '进阶培优',
      value: 11
    }
  ]
}

const gradeArray = [
  {
    label: '一年级',
    value: 7
  },
  {
    label: '二年级',
    value: 8
  },
  {
    label: '三年级',
    value: 9
  },
  {
    label: '四年级',
    value: 10
  },
  {
    label: '五年级',
    value: 11
  },
  {
    label: '六年级',
    value: 12
  },
  {
    label: '七年级',
    value: 1
  },
  {
    label: '八年级',
    value: 2
  },
  {
    label: '九年级',
    value: 3
  },
  {
    label: '高一',
    value: 4
  },
  {
    label: '高二',
    value: 5
  },
  {
    label: '高三',
    value: 6
  }
]

// 学段
const phase = {
  1: '初中',
  2: '高中',
  3: '小学'
}

// 学科
const course = {
  1: '初中语文',
  2: '初中数学',
  3: '初中英语',
  4: '初中物理',
  5: '初中化学',
  6: '初中生物',
  7: '初中历史',
  8: '初中地理',
  9: '高中语文',
  10: '高中数学',
  11: '高中英语',
  12: '高中物理',
  13: '高中化学',
  14: '高中生物',
  15: '高中历史',
  16: '高中地理',
  17: '高中政治',
  19: '初中政治',
  20: '小学奥数',
  21: '小学语文',
  22: '小学数学',
  23: '小学英语',
  24: '初中科学',
  26: '小学综合素质',
  27: '小学科学'
}

// 学科与学段映射（通过id映射）
// 1: '初中', 2: '高中', 3: '小学'
const courseToPhaseById = {
  1: 1,
  2: 1,
  3: 1,
  4: 1,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
  9: 2,
  10: 2,
  11: 2,
  12: 2,
  13: 2,
  14: 2,
  15: 2,
  16: 2,
  17: 2,
  19: 1,
  20: 3,
  21: 3,
  22: 3,
  23: 3,
  24: 1,
  26: 3,
  27: 3
}

// 适用考试
const exam = {
  2: '开学考试',
  3: '期中考试',
  4: '期末考试',
  5: '模拟考试',
  6: '练习',
  7: '月考',
  8: '调研',
  9: '联考',
  10: '会考',
  11: '竞赛',
  12: '真题'
}

const indexInChinese = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十', '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九', '三十', '三十一', '三十二', '三十三', '三十四', '三十五', '三十六', '三十七', '三十八', '三十九', '四十', '四十一', '四十二', '四十三', '四十四', '四十五', '四十六', '四十七', '四十八', '四十九', '五十']

const dict = {
  grade: grade,
  phase: phase,
  course: course,
  phaseToGradeBySelect: phaseToGradeBySelect,
  phaseToGradeById: phaseToGradeById,
  gradeArray: gradeArray,
  paperTypeArray: paperTypeArray,
  gradeToPhaseById: gradeToPhaseById,
  courseToPhaseById: courseToPhaseById,
  exam: exam,
  indexInChinese: indexInChinese
}
export default dict
