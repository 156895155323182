const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  // isHome: state => state.header.isHome,
  sidebar: state => state.app.sidebar,
  userInfo: state => {
    if (state.user.userInfo === 'undefined' || state.user.userInfo === undefined) {
      return null
    }
    return JSON.parse(state.user.userInfo)
  },
  permission_routes: state => state.user.routes,
  sidebarRouters: state => state.user.sidebarRouters
}
export default getters
