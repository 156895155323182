<template>
  <div id="app" class="app">
    <transition-router>
      <router-view />
    </transition-router>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {

    }
  },
  created () {}
}
</script>

<style lang="less">
#app {
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  /*color: rgba(255, 255, 255, 0.9);*/
  position: relative;
}
.line-btn {
  color: #4674e6;
  border: 1px solid #4674e6;
  &:hover, &:focus {
    border: 1px solid #4674e6;
    background-color: #edf1fd;
  }
  &:active {
    border: 1px solid #4674e6;
    background-color: #fff;
  }
}
.el-message-box__close:before {
  color: #6F7586;
}
</style>
