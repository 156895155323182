import axios from '../../plugins/axios'

const getTicketTypeList = (options) => axios.post('/v1/search/tickets/types', options)

const getAllTicketTypeList = () => axios.get('/v1/tickets/types')

const createTicketType = (options) => axios.post('/v1/tickets/types', options)

const editTicketType = (options) => axios.put('/v1/tickets/types/' + options.typeId, options)

const deleteTicketType = (options) => axios.delete('/v1/tickets/types/' + options.typeId)

const getSingleTicketType = (options) => axios.get('/v1/tickets/types/' + options.typeId)

const getTicketsList = (options) => axios.post('/v1/search/tickets', options)

const createTicket = (options) => axios.post('/v1/tickets', options)

const sendTicket = (options) => axios.post('/v1/tickets/issue', options)

const getUserList = () => axios.get('/v1/managers')

const writeOffTicket = (options) => axios.put('/v1/tickets/write/off', options)

const getTicketsEntities = (options) => axios.post('/v1/search/tickets/entities', options)

export default {
  getTicketTypeList,
  getAllTicketTypeList,
  createTicketType,
  editTicketType,
  deleteTicketType,
  getSingleTicketType,
  getTicketsList,
  createTicket,
  sendTicket,
  getUserList,
  writeOffTicket,
  getTicketsEntities
}
