import axios from '../../plugins/axios'
// 初始化
const init = (options) => axios.post('/v1/system', options)
// 查询是否初始化
const checkInit = () => axios.get('/v1/system')
// 存在sessionId时，获取菜单
const getRouters = () => axios.get('/v1/privileges/user/menus')
// 获取用户分页列表
const getUserListByPage = (options) => axios.post('/v1/search/managers', options)
// 新增用户
const addUser = (options) => axios.post('/v1/managers', options)
// 修改用户
const updateUser = (options) => axios.put('/v1/managers/' + options.userId, options)
// 删除用户
const deleteUser = (options) => axios.delete('/v1/managers/' + options)
// 重置密码
const resetUserPassword = (options) => axios.post('/v1/managers/' + options + '/password')

const changePassword = (options, id) => axios.put(`/v1/managers/${id}/password`, options)
// 登录
const login = (options) => axios.post('/v1/sessions/managerLogin', options)
// 后台自动登录
const autoLogin = () => axios.get('/v1/sessions')
// 退出登录
const logout = () => axios.delete('/v1/sessions')
// 发送验证码
const getSmsCode = (options) => axios.post('/v1/sms/sendCode', options)
// 注册
const signUp = (options) => axios.post('/v1/users', options)

// 获取基本信息
const getUserInfo = (options) => axios.get('/v1/users/' + options)

// 保存个人编辑信息
const saveUserInfo = (options) => axios.put('/v1/users/' + options.userId, options)

export default {
  login,
  init,
  checkInit,
  getRouters,
  getUserListByPage,
  addUser,
  updateUser,
  deleteUser,
  resetUserPassword,
  changePassword,
  autoLogin,
  logout,
  getSmsCode,
  signUp,
  getUserInfo,
  saveUserInfo
}
