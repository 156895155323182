import axios from '../../plugins/axios'

const getTeacherList = (options) => axios.post('/v1/teachers', options)

const getTeacherListFromMatchPlatform = (options) => axios.post('/v1/user/consort/teachers', options)

const getTeacherInfo = (options) => axios.get('/v1/teachers/' + options.userId)

const getTeacherInfofromMatchPlatform = (options) => axios.get('/v1/user/consort/teacher_info/' + options.userId)

const auditTeacherInfo = (options) => axios.post('/v1/user/consort/tutor_auth_check', options)

const getLog = (options) => axios.post('/v1/logs', options)

const getStudentList = (options) => axios.post('/v1/students', options)

const getStudentInfo = (options) => axios.get('/v1/students/' + options.userId)

const getParentList = (options) => axios.post('/v1/parents', options)

const getParentInfo = (options) => axios.get('/v1/parents/' + options.userId)

const getAgentList = (options) => axios.post('/v1/agents', options)

const getAgentInfo = (options) => axios.get('/v1/agents/' + options.userId)

const getAgentFans = (options) => axios.post('/v1/agents/fans', options)

export default {
  getTeacherList,
  getTeacherListFromMatchPlatform,
  getTeacherInfo,
  getTeacherInfofromMatchPlatform,
  auditTeacherInfo,
  getLog,
  getStudentList,
  getStudentInfo,
  getParentList,
  getParentInfo,
  getAgentList,
  getAgentInfo,
  getAgentFans
}
