import axios from '../../plugins/axios'

const getUserStatisticsList = (options) => axios.post('/v1/managers/users', options)

const getReportStatisticsList = (options) => axios.post('/v1/managers/reports', options)

const exportUserStatisticsList = (options) => axios.post('/v1/managers/users/export', options)

const exportReportStatisticsList = () => axios.get('/v1/managers/reports/export')

export default {
  getUserStatisticsList,
  getReportStatisticsList,
  exportUserStatisticsList,
  exportReportStatisticsList
}
