import axios from '../../plugins/axios'

const getStageList = () => axios.get('/dictionary/query_stage_list')

const getSubjectList = (options) => axios.get('/dictionary/query_subject_list/?stageName=' + options.stage)

const getFollowersList = () => axios.get('/v1/user/query_followers_list')

const getBookingOrderList = (options) => axios.post('/course/manager/query_booking_orders', options)

const setFollower = (options) => axios.post('/course/manager/assign_follower', options)

const getOrderDetail = (options) => axios.get('/course/manager/order_basic_info/' + options.orderId)

const updateOrder = (options) => axios.post('/course/manager/follower_update_order', options)

const getLog = (options) => axios.post('/v1/logs', options)

const openOrder = (options) => axios.post('/course/manager/follower_open_order', options)

const getProcessingOrderList = (options) => axios.post('/course/manager/query_processing_orders', options)

const getEnrollList = (options) => axios.get('/course/parent/query_sign_tutors/' + options.orderId)

const getTutorInfo = (options) => axios.get('/course/manager/query_tutor_info/' + options.orderId)

const getBrokerageInfo = (options) => axios.get('/course/manager/order_brokerage_info/' + options.orderId)

// 关闭订单
const closeOrder = (options) => axios.post('/course/manager/follower_close_order', options)

// 查询关闭审核列表
const getCloseAuditList = (options) => axios.post('/course/manager/close_audit_list', options)

// 审批订单退款
const closeAudit = (options) => axios.post('/course/manager/close_audit', options)

export default {
  getStageList,
  getSubjectList,
  getFollowersList,
  getBookingOrderList,
  setFollower,
  getOrderDetail,
  updateOrder,
  getLog,
  openOrder,
  getProcessingOrderList,
  getEnrollList,
  getTutorInfo,
  getBrokerageInfo,
  closeOrder,
  getCloseAuditList,
  closeAudit
}
