import axios from '../../plugins/axios'

const getPlanType = () => axios.get('/v1/operation/package/type')

const getPlanList = (options) => axios.post('/v1/operation/package/list', options)

const getExpiryDays = () => axios.get('/v1/operation/package/days')

const submitPlan = (options) => axios.post('/v1/operation/package/submit', options)

const getPlanDetail = (options) => axios.get('/v1/operation/package/' + options.packageId)

const deletePlan = (options) => axios.delete('/v1/operation/package/delete/' + options.packageId)

const getBatchList = (options) => axios.post('/v1/operation/package/send/list', options)

const getProductList = (options) => axios.get('/v1/operation/package/send/product')

const distributeBatch = (options) => axios.post('/v1/operation/package/send', options)

const getBatchDetail = (options) => axios.get('/v1/operation/package/send/info/' + options.transferBatchId)

export default {
  getPlanType,
  getPlanList,
  getExpiryDays,
  submitPlan,
  getPlanDetail,
  deletePlan,
  getBatchList,
  getProductList,
  distributeBatch,
  getBatchDetail
}
