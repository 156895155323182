import axios from '../../plugins/axios'

const getCommissionSetting = () => axios.get('/commission/get_scale_setting')

const getThresholdSetting = () => axios.get('/commission/get_cash_setting')

const setCommissionPercentage = (options) => axios.post('/commission/update_scale_setting', options)

const setThreshold = (options) => axios.post('/commission/update_cash_setting', options)
const getPay = () => axios.get('/commission/get_subsist_setting')
const setPay = (options) => axios.post('/commission/update_subsist_setting', options)

export default {
  getCommissionSetting,
  getThresholdSetting,
  setCommissionPercentage,
  setThreshold,
  getPay,
  setPay
}
