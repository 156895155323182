const baseUrl = window.BASE_URL
// const baseUrl = ''

const baseMockUrl = ''

const uploadFileUrl = baseUrl + '/file/upload/img' // 文件上传地址

const downloadPdfUrl = baseUrl + '/file/upload/pdf' // PDF下载地址

export default {
  install (Vue, options) {
    Vue.prototype.baseMockUrl = baseMockUrl
    Vue.prototype.BaseUrl = baseUrl
    Vue.prototype.uploadFileUrl = uploadFileUrl
    Vue.prototype.downloadPdfUrl = downloadPdfUrl
  },
  baseUrl: baseUrl,
  baseMockUrl: baseMockUrl,
  uploadFileUrl: uploadFileUrl,
  downloadPdfUrl: downloadPdfUrl
}
