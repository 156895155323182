import user from '@/service/modules/user.js'
import Layout from '@/components/Layout/index.vue'
import Content from '@/components/Layout/content.vue'
import { constantRoutes } from '@/router'
import { getTokenName, getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/localStorage.js'
import { toTree } from '@/utils/arrToTree'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  userInfo: getUserInfo(),
  isAskLogin: false,
  routes: [],
  addRoutes: [],
  sidebarRouters: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = JSON.stringify(info)
  },
  SET_IS_ASK_LOGIN: (state, val) => {
    state.isAskLogin = val
  },
  SET_ROUTES: (state, routes) => {
    state.addRoutes = JSON.parse(JSON.stringify(routes))
    state.routes = JSON.parse(JSON.stringify(constantRoutes.concat(routes)))
  },
  SET_SIDEBAR_ROUTERS: (state, routers) => {
    state.sidebarRouters = JSON.parse(JSON.stringify(constantRoutes.concat(routers)))
  }
}

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      user.login(userInfo).then(response => {
        const { data } = response
        if (data.code === 0) {
          let tokenName = getTokenName()
          console.log(data.data[tokenName])
          commit('SET_TOKEN', data.data[tokenName])
          console.log(data.data[tokenName])
          commit('SET_NAME', data.data.userInfo.userName)
          commit('SET_USER_INFO', data.data.userInfo)
          setToken(data.data[tokenName])
          setUserInfo(JSON.stringify(data.data.userInfo))
          if (window.PERMISSIVE_ROUTES) {
            const { rewriteRoutes, sidebarRoutes } = generateRoutes(data.data.functionTags)
            commit('SET_ROUTES', rewriteRoutes) // 动态路由相关
            commit('SET_SIDEBAR_ROUTERS', sidebarRoutes) // 左侧菜单栏
            resolve(rewriteRoutes) // 动态路由相关
          } else {
            resolve()
          }
        } else {
          reject(data.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  autoLogin({ commit }) {
    return new Promise((resolve, reject) => {
      user.autoLogin().then(res => {
        if (res.data.code === 0) {
          commit('SET_NAME', res.data.data.userInfo.userName)
          commit('SET_USER_INFO', res.data.data.userInfo)
          setUserInfo(JSON.stringify(res.data.data.userInfo))
          resolve()
        } else if (res.data.code === 10202) {
          reject(res.data.code)
        } else {
          reject(res.data.message)
        }
      }).catch(err => {
        console.log('user' + err)
        reject(err)
      })
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      user.logout().then(res => {
        if (res.data.code === 0) {
          resolve()
          commit('SET_TOKEN', '')
          commit('SET_NAME', '')
          commit('SET_USER_INFO', {})
          removeToken()
          removeUserInfo()
        } else {
          reject(res.data.message)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  LogoutLocal({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_NAME', '')
    commit('SET_USER_INFO', {})
    removeToken()
    removeUserInfo()
  },
  GenerateRoutes({ commit }) {
    return new Promise((resolve, reject) => {
      user.getRouters().then(res => {
        if (res.data.code === 0) {
          console.log('gendata', res)
          const { rewriteRoutes, sidebarRoutes } = generateRoutes(res.data.data)
          commit('SET_ROUTES', rewriteRoutes)
          commit('SET_SIDEBAR_ROUTERS', sidebarRoutes)
          resolve(rewriteRoutes)
        } else {
          reject(res.data.data.message)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

function generateRoutes(routes) {
  if (!routes) {
    routes = {}
  }
  routes = toTree(routes, 'parentId', 'id')
  const sdata = JSON.parse(JSON.stringify(routes))
  const rdata = JSON.parse(JSON.stringify(routes))
  const sidebarRoutes = filterAsyncRouter(sdata)
  const rewriteRoutes = filterAsyncRouter(rdata, false, true)
  // 根据返回的路由配置默认的"/"重定向页
  if (rewriteRoutes.length > 0) {
    console.log(rewriteRoutes)
    let path = ''
    let find = false
    for (let i = 0; i < rewriteRoutes.length && find === false; i++) {
      if (rewriteRoutes[i].component === Layout && rewriteRoutes[i].children) {
        for (let j = 0; j < rewriteRoutes[i].children.length && find === false; j++) {
          if (rewriteRoutes[i].children[j] === Content && rewriteRoutes[i].children[j].children) {
            path = rewriteRoutes[i].children[j].children[0].path
            find = true
            break
          } else {
            path = rewriteRoutes[i].children[j].path
            find = true
            break
          }
        }
      }
    }
    console.log(sidebarRoutes)
    console.log('redirect path', path)
    rewriteRoutes.unshift({
      path: '/',
      hidden: true,
      redirect: path
    })
    sidebarRoutes.unshift({
      path: '/',
      hidden: true,
      redirect: path
    })
  }
  rewriteRoutes.push({ path: '*', component: () => import('@/views/application/exception/404.vue'), hidden: true })
  console.log('sidebarRoutes', sidebarRoutes)
  console.log('rewriteRoutes', rewriteRoutes)
  return { rewriteRoutes, sidebarRoutes }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'Content') {
        route.component = Content
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view) => { // 路由懒加载
  // return (resolve) => require([`${view}`], resolve)
  // return (resolve) => require([`@/views/${view}`], resolve)
  // view = view.replace('../', '')
  // console.log('view', view)
  // return (resolve) => require([`@/${view}`], resolve)
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default {
  namescaped: false,
  state,
  mutations,
  actions
}
