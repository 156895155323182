 <template>
  <el-header class="ym-header"
             :style="{ height: menuMargin }">
    <div class="header-wrap"
         :style="headerStyle">

      <!-- 左侧logo盒子 -->
      <div class="logo-box"
           v-if="logoOptions"
           :style="{left: logoOptions.left}"
           @click="logoClick">
        <logo class="logo-box-info hidden-xs-only"
              :logoOptions="logoOptions"></logo>
      </div>

      <div class="header-menu"
           :class="menuButton === 'top' ? '': 'hidden-md-and-up'">
        <!--菜单按钮 hidden-sm-and-up-->
        <menu-icon :is-active="sidebar.opened"
                   @toggleClick="toggleSideBar"
                   class="ym-menu-collapse-icon" />
      </div>

      <div class="header-middle hidden-xs-only"
           v-if="showWelcome && (userLoginAccount || userName)">
        <span>欢迎<span class="username">{{userName || userLoginAccount}}</span>登录邻里托管运营中心</span>
      </div>

      <div class="header-right">
        <!-- <div class="theme">
          主题色：
          <el-radio-group v-model="themeCheck" @change="onThemeChange">
            <el-radio-button label="theme-default">默认</el-radio-button>
            <el-radio-button label="theme-yimi">蚁米色</el-radio-button>
          </el-radio-group>
        </div> -->
        <div class="user-info">
          <div class="header-img"
               @click="headerImgClick">
            <img :src="userAvatar"
                 v-errorAlt="'photo'"
                 alt
                 style="width:100%;height:100%">
          </div>
          <el-dropdown class="right-menu"
                       trigger="click">
            <span class="el-dropdown-link">
              {{userName || userLoginAccount}}
              <i class="el-icon-caret-bottom icon-bottom"></i>
            </span>
            <el-dropdown-menu class="ym-header-dropdown"
                              slot="dropdown">
              <el-dropdown-item v-for="item in rightMenu"
                                :key="item.url"
                                :icon="item.icon"
                                @click.native="dropdownClick(item)">
                {{item.title}}
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button"
                                @click.native="loginOut()">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div class="imgWrap">
          <img src="../../assets/img/exit.png" width="28" alt title="退出登录" @click="loginOut">
        </div> -->
      </div>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import baseUrl from '@/config'
import Logo from './Logo'
import MenuIcon from './Menu/MenuIcon'

export default {
  components: {
    MenuIcon,
    Logo
  },
  data () {
    return {
      themeCheck: 'theme-default',
      headerStyle: {}, // 顶部栏外层样式
      menuMargin: 0, // 菜单距离
      userName: '',
      userLoginAccount: '',
      userAvatar: ''
    }
  },
  props: {
    // 定位规则,顶部栏是否固定在窗口顶部, 可选:absolute(不固定)/fixed(固定)
    position: {
      type: String,
      default: 'absolute'
    },

    // 顶部栏背景色
    background: {
      type: String,
      default: ''
    },

    // 顶部栏高度
    height: {
      type: String,
      default: '73'
    },

    // 显示欢迎语
    showWelcome: {
      type: Boolean,
      default: true
    },

    // 在顶部栏处于固定在窗口上 方的情况下,页面滚动的时候 顶部栏所需改变的颜色 ,前置 条件:需position为fixed
    fixedColor: {
      type: String,
      default: '#eee'
    },

    // 顶部栏内容过多或响应宽度过窄时的换行规则.
    // auto表示发生换行,clip不换行 超出模块隐藏, ellipsis不换行显示省略符号来代表被修剪的内容
    wrapStyle: {
      type: String,
      default: 'auto'
    },

    // 右侧下拉菜单
    rightMenu: {
      type: Array,
      default: () => {
        return [{
          icon: 'el-icon-switch-button',
          title: '退出登录',
          url: '/login'
        }]
      }
    },

    logoOption: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 菜单按钮
    menuButton: {
      type: String
    },

    // 用户信息
    userInfo: {
      type: Object,
      default: () => { }
    },

    // 自定义登出
    customizeLogout: {
      type: Function,
      default: null
    }
  },
  mounted () {
    this.initParam()
    window.addEventListener('scroll', this.handleScroll, true)
  },
  watch: {
    userInfo: {
      deep: true,
      handler () {
        this.userName = this.userInfo.userName
        this.userLoginAccount = this.userInfo.userLoginAccount
        if (this.userInfo.userAvatar !== '') {
          this.userAvatar = window.BASE_URL + this.userInfo.userAvatar
        } else {
          this.userAvatar = this.userInfo.userAvatar
        }
      }
    }
  },
  computed: {
    logoOptions () {
      return Object.assign({
        overflow: true
      }, this.logoOption)
    },
    ...mapGetters(['sidebar'])
    // userAvatar() {
    //   if (!this.userInfo) {
    //     return ''
    //   }
    //   if (this.userInfo.userAvatar && this.userInfo.userAvatar.indexOf('http') === -1) {
    //     return baseUrl.baseUrl + '/' + this.userInfo.userAvatar
    //   }
    //   return this.userInfo.userAvatar
    // }
  },
  methods: {
    // 初始化参数
    initParam () {
      let styleObj = {}

      // 初始化顶部栏定位规则
      // styleObj.position = this.position === 'absolute' ? 'absolute' : 'fixed'
      styleObj.position = 'relative'

      // 初始化顶部栏背景颜色
      // styleObj.background = this.background

      // 初始化顶部栏高度
      styleObj.height = (Number(this.height) - 1) + 'px'
      this.menuMargin = this.height + 'px'

      this.$set(this, 'headerStyle', styleObj)

      // 初始化用户名
      this.userName = this.userInfo.userName

      // 初始化用户账号
      this.userLoginAccount = this.userInfo.userLoginAccount

      // 初始化用户头像
      if (this.userInfo.userAvatar !== '') {
        this.userAvatar = window.BASE_URL + this.userInfo.userAvatar
      } else {
        this.userAvatar = this.userInfo.userAvatar
      }
    },

    // logo点击事件
    logoClick () {
      this.$emit('logoClick')
    },

    // 头像点击事件
    headerImgClick () {
      this.$emit('headerImgClick')
    },

    // 滚动条滚动事件
    handleScroll (e) {
      // 仅当fixed定位时开启颜色变换
      if (this.position === 'absolute') {
        return
      }
      // 当前滚动条位置
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      // 滚动大于150变换完毕
      if (scrollTop > 150) {
        this.$set(this.headerStyle, 'background', this.fixedColor)
      } else {
        // 原始颜色
        let colorStart = this.convertHexToRGB(this.background)
        // 目标颜色
        let colorEnd = this.convertHexToRGB(this.fixedColor)
        // 计算过渡色
        let i = scrollTop / 150
        let colorApply = {
          r: (colorEnd.r - colorStart.r) * i + colorStart.r,
          g: (colorEnd.g - colorStart.g) * i + colorStart.g,
          b: (colorEnd.b - colorStart.b) * i + colorStart.b
        }
        let colorApplyStr = 'rgba(' + colorApply.r + ',' + colorApply.g + ',' + colorApply.b + ')'
        this.$set(this.headerStyle, 'background', colorApplyStr)
      }
    },

    // 颜色十六进制转rbg
    convertHexToRGB (color) {
      if (color.length === 4) {
        let extendedColor = '#'
        for (let i = 1; i < color.length; i++) {
          extendedColor += color.charAt(i) + color.charAt(i)
        }
        color = extendedColor
      }
      const values = {
        r: parseInt(color.substr(1, 2), 16),
        g: parseInt(color.substr(3, 2), 16),
        b: parseInt(color.substr(5, 2), 16)
      }
      return values
    },
    ...mapActions(['logout']),
    loginOut () {
      // removeToken()
      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        customClass: 'confirm-box',
        cancelButtonClass: 'line-btn',
        type: 'warning'
      }).then(() => {
        this.$router.push({ name: 'login' })
        this.logout().then(() => { })
      }).catch(() => { })
    },
    dropdownClick (item) {
      this.$emit('dropdownItemClick', item)
    },
    onThemeChange (val) {
      this.toggleTheme(document.body, val)
    },
    /**
     * @param {HTMLElement} element
     * @param {string} className
     */
    toggleTheme (element, className) {
      if (!element || !className) {
        return
      }
      let classArr = element.className.split(' ')
      classArr.forEach((item, index) => {
        if (item.indexOf('theme-') !== -1) {
          classArr.splice(index, 1)
        }
      })
      classArr.push(className)
      element.className = classArr.join(' ')
    },
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>
<style lang="scss">
.ym-header {
  padding: 0 !important;
  margin: 0;
  text-align: left;
  font-size: 12px;
  background: $--header-bg-color;
  color: #333;
  line-height: $--header-height;
  border-bottom: $--header-border;
  width: 100%;
  overflow: hidden;
  z-index: 1000;

  .header-wrap {
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    .logo-box {
      width: $--aside-width;
      float: left;
      text-align: center;
      &-info {
        display: initial;
      }
    }

    .header-menu {
      float: left;
      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }

    .header-middle {
      float: left;
      margin-left: 10px;
      height: 100%;
      color: $--color-text-secondary;
      font-size: $--font-size-medium;
      user-select: none;
      .username {
        // font-weight: 600;
      }
    }

    .header-right {
      height: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: flex-end;
      float: right;

      // .introduce {
      //   color: #ff4a12;
      //   padding-bottom: 5px;
      //   border-bottom: 1px solid #ff4a12;
      //   color: #ff4a12;
      //   font-size: 14px;
      //   margin-right: 80px;
      //   font-weight: 400;
      //   cursor: pointer;
      // }
      .theme {
        padding-right: 20px;
      }
      .user-info {
        font-size: 16px;
        color: $--color-text-primary;
        display: flex;
        align-items: center;
        margin-right: 25px;

        div {
          margin: 0px 10px;
        }
        .right-menu {
          color: $--color-text-primary;
          width: fit-content;
          user-select: none;
        }
        .el-dropdown-link,
        .el-dropdown-link:focus {
          outline: none;
        }
        .icon-bottom {
          margin: 0px 15px;
          color: $--color-text-secondary;
        }
        .header-img {
          display: inline-block;
          width: $--header-avatar-width;
          height: $--header-avatar-width;
          border-radius: 50%;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .imgWrap {
        display: flex;
        align-items: center;

        img {
          display: block;
          margin-right: 40px;
          border: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.el-dropdown-menu__item {
  i {
    margin-right: 5px;
  }
}

a {
  color: $--color-text-regular;
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.ym-header-dropdown {
  a {
    color: $--color-text-primary;
  }
}
</style>
