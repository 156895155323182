import axios from '../../plugins/axios'

const getOperatorBill = (options) => axios.post('/capital/query_platform_records', options)

const getOperatorBalance = () => axios.get('/capital/query_platform_balance')

const getUserBill = (options) => axios.post('/capital/query_users_records', options)

const getWithdrawBill = (options) => axios.post('/capital/query_cash_records', options)

const getAuditList = (options) => axios.post('/capital/audit_cash_records', options)

const handleAudit = (options) => axios.post('/capital/audit_cash', options)

export default {
  getOperatorBill,
  getOperatorBalance,
  getUserBill,
  getWithdrawBill,
  getAuditList,
  handleAudit
}
