import axios from '../../plugins/axios'

const getCommentList = (options) => axios.post('/course/manager/query_order_assess', options)

const getReviewList = (options) => axios.post('/course/manager/query_order_reviews', options)

const getLessonTemplateList = (options) => axios.post('/course/manager/query_teaching_plans', options)

export default {
  getCommentList,
  getReviewList,
  getLessonTemplateList
}
