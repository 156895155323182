import axios from '../../plugins/axios'

const add = (options) => axios.post('/v1/banner/create', options)

const del = (options) => axios.delete('/v1/banner/delete?id=' + options)

const list = (options) => axios.post('/v1/banner/list', options)

const get = (options) => axios.get('/v1/banner/get?id=' + options)

const update = (options) => axios.put('/v1/banner/update', options)

const updateSort = (options) => axios.put('/v1/banner/update/sort', options)

const updateStatus = (options) => axios.put('/v1/banner/update/status', options)

export default {
  add,
  list,
  del,
  get,
  update,
  updateSort,
  updateStatus
}
