import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout/index.vue'
// import Content from '@/components/Layout/content.vue'

Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export const constantRoutes = [
  // ============== 固定路由部分 part1 Start =================
  {
    path: '/home',
    component: Layout,
    redirect: '/',
    hidden: true
  },
  // ============== 固定路由部分 part1 End =================

  // ========== 不使用动态路由时使用 Start ===============
  // {
  //   path: '/',
  //   hidden: true,
  //   redirect: '/operation/plan'
  // },
  // ============ 不使用动态路由时使用 End =================

  // =========== 动态路由部分 Start ====================
  // {
  //   path: '/operation',
  //   name: 'operation',
  //   component: Layout,
  //   meta: {
  //     title: '运营管理',
  //     icon: 'el-icon-takeaway-box',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/operation/batch',
  //       name: 'distribution',
  //       redirect: '/operation/batch/list',
  //       component: Content,
  //       meta: { title: '套餐发放', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/operation/batch/list',
  //           name: 'batchList',
  //           hidden: true,
  //           component: () => import('../views/operation/distribution/index.vue'),
  //           meta: {
  //             title: '套餐发放列表',
  //             activeMenu: '/operation/batch'
  //           }
  //         },
  //         {
  //           path: '/operation/batch/distribute',
  //           name: 'distribute',
  //           hidden: true,
  //           component: () => import('../views/operation/distribution/distribute.vue'),
  //           meta: {
  //             title: '发放',
  //             activeMenu: '/operation/batch',
  //             parent: 'batchList'
  //           }
  //         },
  //         {
  //           path: '/operation/batch/detail/:userId',
  //           name: 'batchDetail',
  //           hidden: true,
  //           component: () => import('../views/operation/distribution/detail.vue'),
  //           meta: {
  //             title: '发放批次详情',
  //             activeMenu: '/operation/batch',
  //             parent: 'batchList'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/operation/plan',
  //       name: 'plan',
  //       redirect: '/operation/plan/list',
  //       component: Content,
  //       meta: { title: '套餐设置', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/operation/plan/list',
  //           name: 'planList',
  //           hidden: true,
  //           component: () => import('../views/operation/plan/index.vue'),
  //           meta: {
  //             title: '套餐列表',
  //             activeMenu: '/operation/plan'
  //           }
  //         },
  //         {
  //           path: '/operation/plan/add',
  //           name: 'addPlan',
  //           hidden: true,
  //           component: () => import('../views/operation/plan/add.vue'),
  //           meta: {
  //             title: '新增套餐',
  //             activeMenu: '/operation/plan',
  //             parent: 'planList'
  //           }
  //         },
  //         {
  //           path: '/operation/plan/edit/:planId',
  //           name: 'editPlan',
  //           hidden: true,
  //           component: () => import('../views/operation/plan/add.vue'),
  //           meta: {
  //             title: '编辑套餐',
  //             activeMenu: '/operation/plan',
  //             parent: 'planList'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/order',
  //   name: 'order',
  //   component: Layout,
  //   meta: {
  //     title: '订单管理',
  //     icon: 'el-icon-receiving',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/order/reservation',
  //       name: 'reservation',
  //       component: Content,
  //       redirect: '/order/reservation/list',
  //       meta: { title: '预约订单', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/order/reservation/list',
  //           name: 'reservationList',
  //           component: () => import('../views/order/matchedOrder/reservation/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '预约订单列表',
  //             activeMenu: '/order/reservation'
  //           }
  //         },
  //         {
  //           path: '/order/reservation/:orderId',
  //           name: 'reservationDetail',
  //           component: () => import('../views/order/matchedOrder/reservation/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '预约订单详情',
  //             parent: 'reservationList',
  //             activeMenu: '/order/reservation'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/order/course',
  //       name: 'course',
  //       component: Content,
  //       redirect: '/order/course/list',
  //       meta: { title: '课时订单', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/order/course/list',
  //           name: 'courseOrderList',
  //           component: () => import('../views/order/matchedOrder/course/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '课时订单列表',
  //             activeMenu: '/order/course'
  //           }
  //         },
  //         {
  //           path: '/order/course/:orderId',
  //           name: 'courseOrderDetail',
  //           component: () => import('../views/order/matchedOrder/course/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '课时订单详情',
  //             parent: 'courseOrderList',
  //             activeMenu: '/order/course'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/feedback',
  //   name: 'feedback',
  //   component: Layout,
  //   meta: {
  //     title: '评价管理',
  //     icon: 'el-icon-chat-line-square',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/feedback/comment',
  //       name: 'comment',
  //       component: () => import('../views/feedback/comment/index.vue'),
  //       meta: {
  //         title: '服务评价'
  //       }
  //     },
  //     {
  //       path: '/feedback/review',
  //       name: 'review',
  //       component: () => import('../views/feedback/review/index.vue'),
  //       meta: {
  //         title: '课程点评'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/userManagement',
  //   name: 'userManagement',
  //   component: Layout,
  //   meta: {
  //     title: '用户管理',
  //     icon: 'el-icon-user',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/userManagement/teacher',
  //       name: 'teacherManagement',
  //       redirect: '/userManagement/teacher/list',
  //       component: Content,
  //       meta: { title: '托管员管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/userManagement/teacher/list',
  //           name: 'teacherList',
  //           component: () => import('../views/userManagement/teacher/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '托管员列表',
  //             activeMenu: '/userManagement/teacher'
  //           }
  //         },
  //         {
  //           path: '/userManagement/teacher/yuntuofu/:userId',
  //           name: 'yuntuofuDetail',
  //           component: () => import('../views/userManagement/teacher/yuntuofu/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '邻里托管平台托管员详情',
  //             activeMenu: '/userManagement/teacher',
  //             parent: 'teacherList'
  //           }
  //         },
  //         {
  //           path: '/userManagement/teacher/matchPlatform/:userId',
  //           name: 'matchPlatformDetail',
  //           component: () => import('../views/userManagement/teacher/matchPlatform/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '撮合平台托管员详情',
  //             activeMenu: '/userManagement/teacher',
  //             parent: 'teacherList'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/userManagement/student',
  //       name: 'studentManagement',
  //       redirect: '/userManagement/student/list',
  //       component: Content,
  //       meta: { title: '学生管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/userManagement/student/list',
  //           name: 'studentList',
  //           component: () => import('../views/userManagement/student/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '学生列表',
  //             activeMenu: '/userManagement/student'
  //           }
  //         },
  //         {
  //           path: '/userManagement/student/:userId',
  //           name: 'studentDetail',
  //           component: () => import('../views/userManagement/student/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '学生详情',
  //             activeMenu: '/userManagement/student',
  //             parent: 'studentList'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/userManagement/parent',
  //       name: 'parentManagement',
  //       redirect: '/userManagement/parent/list',
  //       component: Content,
  //       meta: { title: '家长管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/userManagement/parent/list',
  //           name: 'parentList',
  //           component: () => import('../views/userManagement/parent/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '家长列表',
  //             activeMenu: '/userManagement/parent'
  //           }
  //         },
  //         {
  //           path: '/userManagement/parent/:userId',
  //           name: 'parentDetail',
  //           component: () => import('../views/userManagement/parent/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '家长详情',
  //             activeMenu: '/userManagement/parent',
  //             parent: 'parentList'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/userManagement/agent',
  //       name: 'agentManagement',
  //       redirect: '/userManagement/agent/list',
  //       component: Content,
  //       meta: { title: '代理管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: '/userManagement/agent/list',
  //           name: 'agentList',
  //           component: () => import('../views/userManagement/agent/index.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '代理列表',
  //             activeMenu: '/userManagement/agent'
  //           }
  //         },
  //         {
  //           path: '/userManagement/agent/:userId',
  //           name: 'agentDetail',
  //           component: () => import('../views/userManagement/agent/detail.vue'),
  //           hidden: true,
  //           meta: {
  //             title: '代理详情',
  //             activeMenu: '/userManagement/agent',
  //             parent: 'agentList'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/statement',
  //   name: 'statement',
  //   component: Layout,
  //   meta: {
  //     title: '资金管理',
  //     icon: 'el-icon-money',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/statement/operator',
  //       name: 'operatorStatement',
  //       component: () => import('../views/statement/operator/index.vue'),
  //       meta: {
  //         title: '运营方收支流水'
  //       }
  //     },
  //     {
  //       path: '/statement/user',
  //       name: 'userStatement',
  //       component: () => import('../views/statement/user/index.vue'),
  //       meta: {
  //         title: '用户收支流水'
  //       }
  //     },
  //     {
  //       path: '/statement/withdraw',
  //       name: 'withdrawStatement',
  //       component: () => import('../views/statement/withdraw/index.vue'),
  //       meta: {
  //         title: '提现流水'
  //       }
  //     },
  //     {
  //       path: '/statement/audit',
  //       name: 'auditStatement',
  //       component: () => import('../views/statement/audit/index.vue'),
  //       meta: {
  //         title: '提现审核'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/setting',
  //   name: 'setting',
  //   redirect: '/setting/operation',
  //   component: Layout,
  //   meta: {
  //     title: '运营设置',
  //     icon: 'el-icon-setting',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: '/setting/operation',
  //       name: 'operationSetting',
  //       hidden: true,
  //       component: () => import('../views/setting/index.vue'),
  //       meta: { title: '运营设置', icon: 'el-icon-setting', activeMenu: '/setting' }
  //     }
  //   ]
  // },
  // {
  //   path: '/statistics',
  //   name: 'statistics',
  //   component: Layout,
  //   meta: {
  //     title: '第三方接口统计',
  //     icon: 'el-icon-timer',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: 'user',
  //       name: 'userStatistics',
  //       component: () => import('../views/statistics/user/index.vue'),
  //       meta: {
  //         title: '用户统计'
  //       }
  //     },
  //     {
  //       path: 'report',
  //       name: 'reportStatistics',
  //       component: () => import('../views/statistics/report/index.vue'),
  //       meta: {
  //         title: '测评报告调用统计'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/roleControl',
  //   name: 'roleControl',
  //   component: Layout,
  //   meta: {
  //     title: '角色权限管理',
  //     icon: 'el-icon-lock',
  //     hideInBreadcrumb: true
  //   },
  //   children: [
  //     {
  //       path: 'user',
  //       name: 'user',
  //       component: Content,
  //       redirect: '/roleControl/user/list',
  //       meta: { title: '用户管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: 'list',
  //           name: 'user-list',
  //           hidden: true,
  //           component: () => import('../views/application/user/list.vue'),
  //           meta: { title: '用户列表', activeMenu: '/roleControl/user' }
  //         },
  //         {
  //           path: 'edit',
  //           name: 'user-edit',
  //           hidden: true,
  //           component: () => import('../views/application/user/edit.vue'),
  //           meta: { title: '编辑用户', activeMenu: '/roleControl/user', parent: 'user-list' }
  //         },
  //         {
  //           path: 'add',
  //           name: 'user-add',
  //           hidden: true,
  //           component: () => import('../views/application/user/add.vue'),
  //           meta: { title: '新增用户', activeMenu: '/roleControl/user', parent: 'user-list' }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'userGroup',
  //       name: 'userGroup',
  //       component: Content,
  //       redirect: '/roleControl/userGroup/list',
  //       meta: { title: '用户组管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: 'list',
  //           name: 'userGroup-list',
  //           hidden: true,
  //           component: () => import('../views/application/userGroup/list.vue'),
  //           meta: { title: '用户组列表', activeMenu: '/roleControl/userGroup' }
  //         },
  //         {
  //           path: 'add',
  //           name: 'userGroup-add',
  //           hidden: true,
  //           component: () => import('../views/application/userGroup/edit.vue'),
  //           meta: { title: '新增用户组', activeMenu: '/roleControl/userGroup', parent: 'userGroup-list' }
  //         },
  //         {
  //           path: 'edit',
  //           name: 'userGroup-edit',
  //           hidden: true,
  //           component: () => import('../views/application/userGroup/edit.vue'),
  //           meta: { title: '编辑用户组', activeMenu: '/roleControl/userGroup', parent: 'userGroup-list' }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'userRole',
  //       name: 'userRole',
  //       redirect: '/roleControl/userRole/list',
  //       component: Content,
  //       meta: { title: '角色管理', hideInBreadcrumb: true },
  //       children: [
  //         {
  //           path: 'list',
  //           name: 'userRole-list',
  //           component: () => import('../views/application/userRole/list.vue'),
  //           hidden: true,
  //           meta: { title: '角色列表', activeMenu: '/roleControl/userRole' }
  //         },
  //         {
  //           path: 'add',
  //           name: 'userRole-add',
  //           hidden: true,
  //           component: () => import('../views/application/userRole/edit.vue'),
  //           meta: { title: '新增角色', activeMenu: '/roleControl/userRole', parent: 'userRole-list' }
  //         },
  //         {
  //           path: 'edit',
  //           name: 'userRole-edit',
  //           hidden: true,
  //           component: () => import('../views/application/userRole/edit.vue'),
  //           meta: { title: '编辑角色', activeMenu: '/roleControl/userRole', parent: 'userRole-list' }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // =========== 动态路由部分 End ====================

  // ============ 固定路由部分 part2 Start =============
  /*   {
    path: '/banner',
    icon: 'el-icon-lock',
    component: Layout,
    meta: { title: '轮播图管理', link: false },
    children: [
      {
        path: '/banner/add',
        name: 'banner-add',
        hidden: true,
        component: () => import('../views/banner/add'),
        meta: { title: '编辑/新增', menuShow: false }
      },
      {
        path: '/banner/teacher',
        name: 'banner-teacher',
        component: () => import('../views/banner/teacher'),
        meta: { title: '教师端', menuShow: true }
      },
      {
        path: '/banner/parent',
        name: 'banner-parent',
        component: () => import('../views/banner/parent'),
        meta: { title: '家长端', menuShow: true }
      },
      {
        path: '/banner/student',
        name: 'banner-student',
        component: () => import('../views/banner/student'),
        meta: { title: '学习端', menuShow: true }
      },
      {
        path: '/banner/agency',
        name: 'banner-agency',
        component: () => import('../views/banner/agency'),
        meta: { title: '代理端', menuShow: true }
      }
    ]
  }, */
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('../views/login'),
    meta: { title: '登录' }
  },
  {
    path: '/userSetting',
    component: Layout,
    hidden: true,
    meta: { title: '设置', link: false, hideInBreadcrumb: true },
    children: [
      {
        path: '/userSetting/password',
        name: 'password',
        hidden: true,
        component: () => import('../views/application/password'),
        meta: { title: '修改密码', menuShow: false }
      }
    ]
  },
  // {
  //   path: '/lesson',
  //   component: Layout,
  //   hidden: false,
  //   meta: { title: '课程教案', link: false, hideInBreadcrumb: true },
  //   children: [
  //     {
  //       path: '/lessonTemplate/index',
  //       name: 'lessonTemplate',
  //       hidden: false,
  //       component: () => import('../views/feedback/lessonTemplate/index'),
  //       meta: { title: '课程教案', menuShow: true, icon: '' }
  //     }
  //   ]
  // },
  // ============== 固定路由部分 part2 End =================

  // ============== 生成动态路由使用页 Start =====================
  {
    path: '/test',
    component: () => import('../views/test/index.vue')
  }
  // ============== 生成动态路由使用页 End =====================

  // {
  //   path: '*',
  //   component: () => import('@/views/application/exception/404.vue'),
  //   hidden: true
  // }
]

const createRouter = () => new Router({
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
